const ALL_CONTROLS = `
    id
    order
    label: controlNumber 
    fullLabel: description
    prefix: originNorm {
        id
        name
    }
    created_by{
        id
        username
    }`

const NORMS = `
    id
    name
    displayName
    created_by{
        id
        username
    }
    version
    ` 
export default { ALL_CONTROLS, NORMS }