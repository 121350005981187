

























































import { defineComponent, watch, ref } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import variables from '@/shared/variables'
import utils from '@/shared/mixins/utils'
import { UserRole } from '@/shared/enum/general-enum'
import apolloClient from '@/shared/services/ApolloCLientAPI'

export default defineComponent({
    name: 'RelationSection',

    props: {
        leftType: {
            type: String,
            required: true,
        },
        leftItems: {
            type: Array,
            required: true,
        },
        intialLeftItemSelection: {
            type: Number
        },
        rightType: {
            type: String,
            required: true,
        },
        rightItems: {
            type: Array,
            required: true,
        },
        entity: {
            type: String,
        },
        isControlReference: {
            type: Boolean,
        },
    },

    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const rightItemsCopy = ref([])
        const selectedLeftItem = ref(null)
        const selectedRightItems = ref([])
        const selection = ref([])
        const selectedSelection = ref([])
        const { role } = useState(['role'])
        const loading = ref(false);

        const typeMapping = {
            'Threats': 'threat',
            'Vulnerabilities': 'vulnerability',
            'Controls': 'control',
            'Bedreigingen': 'bedreiging',
            'Kwetsbaarheden': 'kwetsbaarheid',
            'Beheersmaatregelen': 'beheersmaatregel'
        };

        /* Since control reference has controls for both dropdown and the list, remove selected control from list */
        const removeSelectedDropdownValue = () => {
            const idx = rightItemsCopy.value.findIndex((ri) => ri.id === selectedLeftItem.value.id)
            if (idx > 0) rightItemsCopy.value.splice(idx, 1)
        }
        // push rightItems to rightItemsCopy again to update list array when switching to different options
        const resetRightItems = () => {
            rightItemsCopy.value = []
            // Copy the immutable prop to rightItemsCopy
            props.rightItems.forEach((val) => rightItemsCopy.value.push(Object.assign({}, val)))
            // order by order number
            rightItemsCopy.value.sort((a, b) => (a.order > b.order ? 1 : -1))
        }

        // On selection of dropdown item
        watch(selectedLeftItem, async () => {
            emit('change-left-item-selection', selectedLeftItem.value.id)
            // Unselect rightItems
            selectedRightItems.value = []
            // reset selection
            selection.value = []
            resetRightItems()
            if (props.isControlReference) removeSelectedDropdownValue()
            // Move selection back to rightItems
            rightItemsCopy.value.push(...selection.value)

            try {
                loading.value = true
                let selectedType = '';

                if(language.value === 'nl') {
                    const translations = {
                        'bedreiging': 'threat',
                        'kwetsbaarheid': 'vulnerability',
                        'beheersmaatregel': 'control'
                    }

                    selectedType = translations[typeMapping[props.leftType]];

                } else selectedType = typeMapping[props.leftType];

                 
                if(selectedType) {
                    // get risks of threats, vulnerabilities or controls
                    const relationRisksQuery = `{
                        id
                        order
                        children: risks {
                            id
                            order
                            label: description
                            fullLabel: fullDescription
                        }
                    }`

                    const query = `query {
                        ${selectedType}(id: ${selectedLeftItem.value.id}) ${relationRisksQuery}
                    }`;

                    const result = await apolloClient.getGraphqlData(query);
                    const children = result.data[selectedType].children;

                    // add children to selection
                    selection.value.push(...children)
                    // remove children from rightItems
                    for (const child of children) {
                        const idx = rightItemsCopy.value.findIndex((ri) => ri.id === child.id)
                        rightItemsCopy.value.splice(idx, 1)
                    }
                }

                // sort
                selection.value.sort((a, b) => (a.order > b.order ? 1 : -1))
                rightItemsCopy.value.sort((a, b) => (a.order > b.order ? 1 : -1))
            } catch (err) {
                throw Error(`Error while retrieving ${typeMapping[props.leftType]}: ${err}`)
            }
            finally {
                loading.value = false
            }
        })

        watch(
            () => props.rightItems,
            () => {
                resetRightItems()
            },
            {
                immediate: true,
            }
        )

        // Allow parent component to set initial selection for left item through a prop
        watch(
            () => props.intialLeftItemSelection,
            (selectedLeftItemID) => {
                if (selectedLeftItemID) {
                    selectedLeftItem.value = props.leftItems.find((item: {id: number}) => item?.id === selectedLeftItemID)
                }
            },
            { immediate: true }
        )

        // Move from rightItems to selection
        const select = () => {
            for (const selectedItem of selectedRightItems.value) {
                const idx = rightItemsCopy.value.indexOf(selectedItem)
                const removed = rightItemsCopy.value.splice(idx, 1)[0]
                selection.value.push(removed)
                // sort
                selection.value.sort((a, b) => (a.order > b.order ? 1 : -1))
            }
            // Unselect rightItems
            selectedRightItems.value = []
        }

        const unselect = () => {
            for (const selectedItem of selectedSelection.value) {
                const idx = selection.value.indexOf(selectedItem)
                const removed = selection.value.splice(idx, 1)[0]
                rightItemsCopy.value.push(removed)
                // sort
                rightItemsCopy.value.sort((a, b) => (a.order > b.order ? 1 : -1))
            }
            // unselect selected leftItems
            selectedSelection.value = []
        }

        const save = () => {
            emit('save-relations', selectedLeftItem.value, selection.value)
            // Unselect rightItems
            selectedRightItems.value = []
            // clear selected items
            selectedSelection.value = []
        }

        const makeDropdownLabel = (item) => {
            let res = `${item.order} - `
            if (item.prefix) {
                res = res + `${item.prefix.name} - `
            }
            if (item.label) {
                res = res + `${item.label}`
            }
            if (item.fullLabel) {
                res = res + ` - ${item.fullLabel.substring(0, 110)}`
                /* res = res + ` - ${item.fullLabel}` */
            }
            return res
        }

        const makeListLabel = (item) => {
            let res = `${item.order} - `
            if (item.prefix) {
                res = res + `${item.prefix.name} - `
            }
            if (item.label) {
                res = res + `${item.label}`
            }
            if (item.fullLabel) {
                // limit description to 150 characters for controls references
                res =
                    res +
                    ` - ${
                        props.isControlReference ? item.fullLabel.substring(0, 150) : item.fullLabel
                    }`
            }
            return res
        }

        const getDropdownLabel = (value: string): string => {
            if (value) {
                return makeDropdownLabel(value);
            } else {
                return `${t('SELECT', language)} ${typeMapping[props.leftType] 
                    ? typeMapping[props.leftType] 
                    : t('CONTROL', language).toLowerCase()}`;
            }
        }

        return {
            makeDropdownLabel,
            makeListLabel,
            language,
            rightItemsCopy,
            select,
            save,
            selection,
            selectedLeftItem,
            selectedRightItems,
            selectedSelection,
            t,
            unselect,
            variables,
            role,
            utils,
            UserRole,
            loading,
            typeMapping,
            getDropdownLabel
        }
    },
})
