































































































































































import { defineComponent, onMounted, ref, computed, Ref } from '@vue/composition-api'
import { useActions, useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import RelationSection from '@/components/relations/RelationSection.vue'
import variables from '@/shared/variables'
import { useToast } from 'vue-toastification/composition'
import { ControlDTO } from '@/dto/backend-response/controlsDTO'
import ctrRefQuery from '@/shared/queries/controlReferences'
import { UserRole } from '@/shared/enum/general-enum'
export default defineComponent({
    components: { RelationSection },
    setup() {
        const { t } = useI18n()
        const { role } = useState(['role'])
        const { language } = useState(['language'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { menuOpen } = useState(['menuOpen'])
        const SELECTED_NORM = ref(null)
        const REF_TARGET_NORM_FILTER = ref(null)
        const REF_SOURCE_CONTROL_FILTER = ref(null)
        const EDIT_TARGET_NORM = ref(null)
        const controls = ref(null)
        const targetNormControls = ref(null)
        const loading = ref(false)
        const toast = useToast()
        const norms = ref(null)
        const controlMatrix = ref(null)

        const fetchNorms = async (): Promise<Array<unknown>> => {
            const getNormsQuery = `
                query{
                    norms{
                        items{
                            ${ctrRefQuery.NORMS}
                        }
                    }
                }
            `
            const result = await apolloClient.getGraphqlData(getNormsQuery)
            return result.data.norms.items
        }

        const formatNorms = (norms, includeAllNorms: boolean) => {
            const {selectedNorms} = useState(["selectedNorms"]) as {selectedNorms: Ref<Array<{id: number; name: string}>>};
            const normSelected = (norm) => selectedNorms.value.find(
                (selectedNorm => selectedNorm.id === norm.id)
            ) ? true : false
            const normLabel = (norm) => {
                const nameWithVersion = norm.version ? `${norm.name} (version: ${norm.version})` : norm.name
                const selectedSuffix = normSelected(norm) ? "" : " [Not selected]"
                return `${norm.displayName || nameWithVersion}${selectedSuffix}`
            }
            const displayNorm = (norm) => norm.created_by.username === variables.DEFAULT_USERNAME && 
                            (includeAllNorms || normSelected(norm))
            return norms
                .filter(displayNorm)
                .map((norm) => ({...norm, name: normLabel(norm)}))
                .sort((a, b) => a.name.localeCompare(b.name)) ?? []
        }

        const getNorms = async () => {
            loading.value = true
            const includeAllNorms: boolean = (role.value === UserRole.SUPER_ADMIN)
            try {
                const normsResponse = await(fetchNorms())
                norms.value = formatNorms(normsResponse, includeAllNorms)
            }
            catch {
                toast.error(t("CONTROL_REFERENCES_LOAD_ERROR"))
            }
            finally {
                loading.value = false
            }
        }

        const getControlMatrix = async (normId: number) => {
            const controlMatrixQuery = `
            query controlReferenceMatrix($fromNorm:Int, $restrictToSelectedNorms:Boolean){
                controlReferenceMatrix(fromNorm:$fromNorm, restrictToSelectedNorms:$restrictToSelectedNorms){
                    matrix {
                        fromControl {
                            ${ctrRefQuery.ALL_CONTROLS}
                        }
                        toControl {
                            ${ctrRefQuery.ALL_CONTROLS}
                        }
                    }
                }
            }
            `
            const restrictToSelectedNorms = (role.value !== UserRole.SUPER_ADMIN)

            const result = await apolloClient.getGraphqlData(controlMatrixQuery, {
                fromNorm: normId,
                restrictToSelectedNorms: restrictToSelectedNorms
            })
            return result.data.controlReferenceMatrix
        }
        

        const getNormControls = async (normId: number) => {
            const query = `
                query controls($controlFilters:ControlFilterOptionsInput, $bySelectedNorms:Boolean){
                    controls(controlFilters:$controlFilters, bySelectedNorms: $bySelectedNorms){
                    items{
                        ${ctrRefQuery.ALL_CONTROLS}
                        }
                    }
                }
                `
            // variable params for query
            const controlsVariables = {
                controlFilters: {
                    norm: normId,
                    orderBy: [
                        {sortField: "order", direction: "asc"}
                    ]
                },
                bySelectedNorms: false,

            }
            const result = await apolloClient.getGraphqlData(query, controlsVariables)
            return (result?.data?.controls?.items ?? [])
                .filter(    // Hide user-created controls
                    (val) => val['created_by'].username === variables.DEFAULT_USERNAME
                )
        }


        const selectSourceNorm = async ({value: normId}) => {
            loading.value = true;
            controls.value = []
            controlMatrix.value = null
            REF_SOURCE_CONTROL_FILTER.value = null
            REF_TARGET_NORM_FILTER.value = null
            EDIT_TARGET_NORM.value = null
            targetNormControls.value = null
            try {
                const [sourceNormControlsResult, matrixResult] = await Promise.all([
                    getNormControls(normId),
                    getControlMatrix(normId),
                ])
                
                controls.value = sourceNormControlsResult
                controlMatrix.value = matrixResult?.matrix ?? []
            }
            catch (err) {
                toast.error(t("CONTROL_REFERENCES_LOAD_ERROR"))
                throw err
            }
            finally {
                loading.value = false;
            }
        }

        const setEditTargetNorm = async () => {
            targetNormControls.value = null
            // Clear filter to make sure all references for the edited source control are shown
            REF_TARGET_NORM_FILTER.value = null
            if (EDIT_TARGET_NORM.value && role.value === UserRole.SUPER_ADMIN) {
                try {
                    loading.value = true
                    targetNormControls.value = await getNormControls(EDIT_TARGET_NORM.value)
                }
                catch (error) {
                    toast.error(t("CONTROL_REFERENCES_LOAD_ERROR"))
                    throw error
                }
                finally {
                    loading.value = false;
                }
            }
        }

        // When superadmin clicks a reference, show editor for that source control + target norm combination
        const selectReference = async (event) => {
            if (event.value && role.value === UserRole.SUPER_ADMIN) {
                // Show references from all norms
                REF_SOURCE_CONTROL_FILTER.value = Number(event.value.split('-')?.[0])
                EDIT_TARGET_NORM.value = Number(event.value.split('-')?.[1])
                setEditTargetNorm()
            }
        }

        const SELECTED_REFERENCE = computed(
            () => REF_SOURCE_CONTROL_FILTER.value + '-' + EDIT_TARGET_NORM.value
        )

        // Save control references to selected target norm
        const saveControlReferences = async (
            control: { id: number },
            referenceControls: ControlDTO[]
        ) => {
            if (control) {
                const controlIds = referenceControls.map((control) => control.id)
                const query = `mutation ($input: ControlReferenceInput!) {
                    updateControlReferences(id: ${control.id}, input: $input) {
                        status
                    }
                }`
                const input = {
                    controls: controlIds,
                    targetNormId: EDIT_TARGET_NORM.value
                }
                loading.value = true
                try {
                    const result = await apolloClient.updateGraphqlData(query, input)
                    if (result.data.updateControlReferences.status !== true) {
                        throw Error("Backend error while saving control references.")
                    }
                    if (result) {
                        const matrixResult = await getControlMatrix(SELECTED_NORM.value)
                        controlMatrix.value = matrixResult?.matrix ?? []
                    }
                } catch (err) {
                    toast.error(t('CONTROL_REFERENCES_SAVE_ERROR', language.value))
                    throw err
                }
                finally{
                    loading.value = false
                }
            }
        }


        const makeLabel = (item, fields) => fields.map(
            (field) => field(item)
        ).filter(
            v => v
        ).join(' - ')

        const makeControlLabel = (control) => makeLabel(
            control,
            [
                control => control?.order,
                control => control?.prefix?.displayName ?? control?.prefix?.name,
                control => control?.label,
                control => control?.fullLabel
            ]
        )?.substring(0, 200)

        // Build the list of referenced controls for the top view box based on control matrix and filter settings
        const filteredControlReferences = computed(() => {
            // Predicate to determine if a referenced control is shown with filters
            const showReference = (reference) =>
                // Show reference if target filter is set to show all or if reference matches the selected norm
                (REF_TARGET_NORM_FILTER.value === null
                    || reference.toControl.prefix.id === REF_TARGET_NORM_FILTER.value)
                // And if source control is set to show all or if matches the selected control
                && (REF_SOURCE_CONTROL_FILTER.value === null
                    || reference.fromControl.id === REF_SOURCE_CONTROL_FILTER.value)
            // Build label for each reference
            const referenceLabel = (reference) => 
                `${reference.fromControl.label} <=> ${makeControlLabel(reference.toControl)}`
            const references = (controlMatrix.value ?? [])
                .filter(showReference)
                .map((reference) => ({
                    label: referenceLabel(reference),
                    targetControlId: reference.fromControl.id,
                    targetNormId: reference.toControl.prefix.id,
                    value: reference.fromControl.id + '-' + reference.toControl.prefix.id
                }))
            return references.sort(
                (a, b) => a.label.localeCompare(b.label, undefined, { numeric: true })
            )
        })

        /*
         * RelationSection expects a list of source entities that each contain a list of "children" that they
         * have a relation to.
         * So we need to transform the reference matrix (which has the structure of a list of (source, target) pairs)
         * to match that data structure.
         */
        const sourceControlsWithRefs = computed(() => {
            // Retrieve referfenced controls (children) from the reference matrix for a single source control
            const getReferencedControls = (sourceControlId, targetNormId) =>
                (controlMatrix.value ?? [])
                    .filter(
                        (ref) => ref.fromControl.id === sourceControlId && ref.toControl.prefix.id === targetNormId
                    )
                    .map((ref) => ref.toControl)
            // Use this function to add the children to each control in the source norm
            return (controls.value ?? []).map(
                (sourceControl) =>
                ({
                    ...sourceControl,
                    children: getReferencedControls(sourceControl.id, EDIT_TARGET_NORM.value)
                })
            )
        })

        // Exclude selected source norm from target norms because in the domain model controls 
        // from the same norm are not allowed to reference each other.
        const targetNormOptions = computed(
            () => norms.value?.filter(
                (norm) => norm.id != SELECTED_NORM.value
            ) ?? []
        )

        onMounted(async () => {
            getNorms()
        })

        return {
            t,
            language,
            utils,
            menuOpen,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            SELECTED_NORM,
            SELECTED_REFERENCE,
            REF_TARGET_NORM_FILTER,
            REF_SOURCE_CONTROL_FILTER,
            targetNormOptions,
            EDIT_TARGET_NORM,
            targetNormControls,
            saveControlReferences,
            controls,
            norms,
            loading,
            makeControlLabel,
            role,
            UserRole,
            controlMatrix,
            filteredControlReferences,
            sourceControlsWithRefs,
            selectSourceNorm,
            setEditTargetNorm,
            selectReference,
        }
    },
})
